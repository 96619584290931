.link {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (--m) {
    flex-direction: row;
  }
}

.trustpilotStars {
  width: 115px;

  @media (--m) {
    margin-right: 11px;
  }
}

.trustpilotScoreWrapper {
  display: flex;
  align-items: center;
  height: 26px;
}

.trustpilotText {
  color: var(--ds-color-mineShaft);
  font-size: 17px;
  line-height: 26px;
  font-weight: 700;
  margin-right: 8px;
}

.trustpilotScoreMobileOnly {
  @media (--m) {
    display: none;
  }
}

.numberOfReviews {
  color: var(--ds-color-mineShaft);
  font-size: 14px;
  font-weight: 700;
  margin-right: 4px;
}

.reviewsOn {
  color: var(--ds-color-mineShaft);
  font-size: 14px;
  margin-right: 4px;
}

.trustpilotLogo {
  width: 72px;
  max-width: none;
  padding-bottom: 5px;

  @media (--m) {
    padding-bottom: 1px;
  }
}
