.holdingImage {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-color-gainsboro);
}

.icon {
  width: 40px;
  height: 40px;
  color: var(--ds-color-asphaltGrey);
}

.holdingImageUnavailable {
  filter: blur(8px);
}
