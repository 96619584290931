.legalFooterContainer {
  max-width: var(--ds-dimensions-max);
  padding-right: calc(var(--ds-space-2-x) + env(safe-area-inset-right));
  padding-left: calc(var(--ds-space-2-x) + env(safe-area-inset-left));
  margin: var(--ds-space-6-x) auto var(--ds-space-6-x) auto;
  text-align: center;
  padding-bottom: calc(var(--ds-space-2-x) * 6 + env(safe-area-inset-bottom));
}

.legalFooterContainer p {
  text-align: center;
  font-size: var(--ds-font-size-4xs);
  line-height: 14px;
  color: var(--ds-color-dove);
}
