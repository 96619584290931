.wrapper {
  display: flex;
  flex-direction: column;
  color: var(--ds-color-mineShaft);

  @media (--m) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.textWrapper {
  @media (--m) {
    max-width: 460px;
  }
}

.imageWrapper {
  position: relative;
  width: 0;
  min-width: 100%;
  max-width: 100%;
  height: 260px;
  min-height: 100%;
  max-height: 100%;
  margin-bottom: var(--ds-space-4-x);

  @media (--s) {
    height: 530px;
  }

  @media (--m) {
    width: 400px;
    min-width: initial;
    max-width: initial;
    height: 300px;
    min-height: initial;
    max-height: initial;
    margin-bottom: 0;
  }
}

.imageWrapper span {
  border-radius: var(--ds-space-2-x);
}

.heading {
  margin-bottom: var(--ds-space-2-x);

  @media (--s) {
    margin-bottom: var(--ds-space-3-x);
  }

  @media (--m) {
    margin-bottom: 18px;
  }
}

.body {
  margin-bottom: var(--ds-space-4-x);
  font-size: 18px;
  line-height: 26px;

  @media (--s) {
    font-size: 22px;
    line-height: var(--ds-space-4-x);
  }
}

.button {
  width: 100%;
  border: 1px solid var(--ds-color-deepPurple);
  color: var(--ds-color-deepPurple);

  @media (--s) {
    width: 302px;
  }
}
