.priceContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1 1 auto;
  margin-top: var(--ds-space-2-x);
}

.smallFontSize span {
  font-size: var(--ds-font-size-s);

  @media (--s) {
    font-size: var(--ds-font-size-m);
  }
}

.wasPrice {
  display: block;
  color: var(--ds-color-monza);
}

.cashPrice {
  color: var(--ds-color-mineShaft);
  padding-bottom: var(--ds-space-1-x);
}

.cashPricePreAdminFeeCompliance {
  padding-bottom: 0;
}
