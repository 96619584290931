.root {
  padding-bottom: var(--ds-space-4-x);
  margin-bottom: var(--ds-space-4-x);
  border-bottom: 2px solid var(--ds-color-gainsboro);
  display: flex;
  flex-direction: column;

  @media (--s) {
    flex-direction: row;
  }
}

.featured {
  @media (--s) {
    border-top: 2px solid var(--ds-color-gainsboro);
    padding-top: var(--ds-space-4-x);
  }
}
