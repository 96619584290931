.table {
  font-size: var(--ds-font-size-xs);
  margin-bottom: var(--ds-space-2-x);
  width: 100%;

  th {
    text-align: left;
    font-weight: var(--ds-font-weight-bold);
  }

  &.small {
    th,
    td {
      padding: var(--ds-space-1-x) var(--ds-space-2-x);
    }
  }

  th,
  td {
    padding: var(--ds-space-2-x) var(--ds-space-1-x);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    @media (--s) {
      padding: var(--ds-space-2-x) var(--ds-space-3-x);
    }
  }

  tr {
    border-bottom: 1px solid var(--ds-color-gainsboro);
  }
}
