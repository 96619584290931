.pagination {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*
  Visually hide items 4-5, and 9-10 in mobile view, 
  when there are multiple ellipsis rendering
*/
.listWithMultipleEllipses .listItem:nth-child(n + 4):nth-child(-n + 5),
.listWithMultipleEllipses .listItem:nth-child(n + 9):nth-child(-n + 10) {
  display: none;

  @media (--s) {
    display: block;
  }
}

.listItem {
  width: var(--ds-space-5-x);
  height: var(--ds-space-5-x);
  color: var(--ds-color-deepPurple);
  order: 3;

  &:first-child,
  &:last-child {
    width: calc(50% - var(--ds-space-1-x));
    margin-bottom: var(--ds-space-2-x);
  }

  /* Prev button - mobile view */
  &:first-child {
    order: 1;
  }

  /* Next button - mobile view */
  &:last-child {
    margin-left: var(--ds-space-2-x);
    order: 2;
  }

  @media (--s) {
    margin-right: clamp(0.125rem, -1.375rem + 2.3438vw, 0.5rem);
    order: initial;

    &:first-child,
    &:last-child {
      width: 80px;
      margin-bottom: 0;
      order: initial;
    }

    &:last-child {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.ellipses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nextPrevButton {
  box-shadow: inset 0 0 0 1px var(--ds-color-deepPurple);
  background-color: var(--ds-color-pureWhite);
  color: var(--ds-color-deepPurple);
  transition: box-shadow 0.1s ease-in-out, color 0.1s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.nextPrevButton:focus {
  box-shadow: inset 0 0 0 2px var(--ds-color-meteorite);
  color: var(--ds-color-meteorite);
}

.nextPrevButton:hover {
  box-shadow: inset 0 0 0 2px var(--ds-color-meteorite);
  color: var(--ds-color-meteorite);
}

.nextPrevButton:disabled,
.nextPrevButton[aria-disabled='true'] {
  box-shadow: inset 0 0 0 1px var(--ds-color-gainsboro);
  color: var(--ds-color-asphaltGrey);
  cursor: not-allowed;
}

.pageNumberButton {
  background-color: var(--ds-color-pureWhite);
  transition: all 0.1s ease-in-out;
  outline: none;
  color: var(--ds-color-deepPurple);

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.pageNumberButton:focus {
  box-shadow: inset 0 0 0 2px var(--ds-color-meteorite);
  color: var(--ds-color-meteorite);
}

.pageNumberButton:hover {
  background-color: var(--ds-color-deepPurple);
  color: var(--ds-color-pureWhite);
}

.pageNumberButtonActive {
  background-color: var(--ds-color-vividViolet);
  color: var(--ds-color-pureWhite);
}

.pageNumberButtonActive:focus {
  color: var(--ds-color-pureWhite);
}

.button {
  border: none;
  cursor: pointer;
  border-radius: var(--ds-space-half-x);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
