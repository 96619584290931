.article {
  margin-bottom: var(--ds-space-5-x);

  @media (--m) {
    margin-bottom: var(--ds-space-6-x);
  }
}

.articleBody {
  line-height: 26px;

  @media (--m) {
    font-size: var(--ds-font-size-l);
    line-height: var(--ds-space-4-x);
  }
}

.articleTitle.articleTitle {
  margin-bottom: var(--ds-space-1-x);
  color: var(--ds-color-mineShaft);
  font-size: 22px;
  line-height: 26px;

  @media (--s) {
    margin-bottom: var(--ds-space-2-x);
    line-height: 28px;
  }

  @media (--m) {
    line-height: var(--ds-space-4-x);
  }
}

.articleButton {
  width: 100%;
  border-width: 1px;
  border-color: var(--ds-color-deepPurple);
  color: var(--ds-color-deepPurple);

  @media (--s) {
    width: 343px;
  }
}

.articleButton:link,
.articleButton:visited {
  color: var(--ds-color-deepPurple);
}
