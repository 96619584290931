.listItem {
  color: #311b77 !important;
  padding-bottom: 1rem;
}

.helpfulArticles {
  @media (--m) {
    width: 450px;
    border-left: 1px solid #e8e8e8;
    padding-left: 48px;
  }
}

.carFinanceItemWrapper {
  padding-bottom: 2rem;

  @media (--m) {
    padding-bottom: 0;
  }
}
