/* stylelint-disable scale-unlimited/declaration-strict-value */
.container {
  display: block;
  width: 140px;
  padding: 20px 12px;
  border: solid 0.063rem #e1dafb;
  border-radius: 1rem;
  text-align: center;

  @media (--xxxs) {
    width: 164px;
  }

  @media (min-width: 425px) {
    width: 189px;
  }

  @media (--s) {
    width: 200px;
  }
  @media (--m) {
    width: 7.438rem;
    height: 6.25rem;
    padding: 0.9rem 0.4rem;
  }
}

.container img {
  max-width: 105px;

  @media (--s) {
    max-width: 170px;
  }

  @media (--m) {
    max-width: 105px;
  }
}

.text {
  color: #311b77;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
