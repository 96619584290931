.wrapper {
  z-index: 1;
}

.list {
  padding: var(--ds-space-3-x) 0;
  list-style: none;

  @media (--m) {
    padding: var(--ds-space-5-x) 0;
  }
}

.listItem {
  display: inline;
}

.listItem + .listItem::before {
  padding: var(--ds-space-1-x);
  content: '/\00a0';
}

.listItem a {
  text-decoration: underline;
}
