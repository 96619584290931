/* stylelint-disable scale-unlimited/declaration-strict-value */
.accordion {
  color: var(--ds-color-mineShaft);
  width: 100%;
}

.accordionTitle {
  /* ideally an !important wouldnt be required here but some accordions appear on Content/Block/Section pages, this in turn causes the Accordion Title to have margin-bottom: 8px; */
  margin: 0 !important;
}

.accordionItemContainer {
  transition: background-color 0.2s ease-in-out;

  &__standard {
    background-color: var(--ds-color-pureWhite);
  }

  &__alternative {
    background-color: var(--ds-color-morningFrost);
  }

  &__cinchCover {
    padding-left: var(--ds-space-2-x);
    padding-right: var(--ds-space-2-x);
    background-color: var(--ds-color-pureWhite);
    border-radius: var(--ds-space-1-x);
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.accordionButton:hover {
  background-color: var(--ds-color-pampas);
  color: var(--ds-color-meteorite);
}

.accordionButton {
  display: flex;
  align-items: center;
  padding: var(--ds-space-2-x) 0;
  width: 100%;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px var(--ds-color-gainsboro);
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-m);
  cursor: pointer;
  min-height: 58px;
  transition: all 0.25 ease-in-out;
  text-align: left;

  &__cinchCover:hover {
    background-color: var(--ds-color-pureWhite);
    color: var(--ds-color-meteorite);
  }

  &__cinchCover {
    font-weight: 400;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.chevron {
  margin-left: auto;
  transition: transform 0.25s ease-in-out;
  flex-shrink: 0;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.chevronRotate {
  transform: rotate(180deg);
}

.accordionContentContainer {
  height: 0;
  overflow: hidden;
  transition: height 0.25s ease-in-out;

  &__cinchCover {
    color: #2f2f2f;
    height: fit-content !important;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.accordionContentInnerContainer {
  width: 100%;
  background: var(--ds-color-pampas);
  padding: var(--ds-space-2-x);

  &__cinchCover {
    padding: 0;
    background: var(--ds-color-pureWhite);
  }
}

.icon {
  margin-right: var(--ds-space-1-and-a-half-x);
  flex-shrink: 0;
}
