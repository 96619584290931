.container {
  margin: 0 auto;
  width: 300px;
  text-align: center;
}

.trustpilotStars {
  padding: 0 3rem 0.5rem;
}

.trustpilotLogo {
  width: 115px;
}
