/* stylelint-disable scale-unlimited/declaration-strict-value */
.navCard {
  width: 100%;
  padding: 24px 24px 20px;
  border: 0.063rem solid #e1dafb;
  background-color: #f8f6ff;
  border-radius: 1rem;

  @media (--m) {
    width: 311px;
    height: 360px;
  }
}

.navCard img {
  width: 100%;
}

.headingContainer {
  font-size: 1rem;
  line-height: 1.5rem;
}

.heading {
  padding-bottom: 0.5rem;
  color: #8f1dbf;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.625rem;
}

.subHeading {
  font-size: 1.125rem;
  padding-bottom: 1rem;
  color: #2f2f2f;
  font-weight: 400;

  @media (--m) {
    font-size: 1rem;
  }
}

.link {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  color: #1565c0 !important;
  font-size: 18px;
  text-align: center;

  @media (--m) {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}

.icon {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.image {
  border-radius: 0.5rem;
}
