/* stylelint-disable scale-unlimited/declaration-strict-value */
.menuIconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (--m) {
    display: none;
  }
}

.menuIconWrapper > button {
  display: flex;
  min-width: 3.5rem;
  min-height: 3.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 0;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

.mobileMenuWrapper {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 446px;
  height: 100vh;
  background-color: #fff;
  color: black;
  overflow-y: scroll;

  @media (--s) {
    width: 27.875rem;
  }
}

.circleBackground {
  background-image: url('https://a.storyblok.com/f/132543/1280x1237/79ca07d7ab/vector.webp');
  background-position: 100% 111%;
  background-repeat: no-repeat;
  background-size: clamp(10px, 38vh, 373px);

  @media (max-height: 580px) {
    background-image: none;
  }
}

.dropCircle {
  background-position: 100% 130%;
}

.navItemDropdown {
  padding: 1.5rem 0 3rem;
  background-color: white;

  @media (--s) {
    padding-top: 1.5rem;
  }

  @media (--m) {
    padding: 1.5rem;
  }
}

.navItem {
  color: #311b77;
  height: 5.5rem;
  flex-basis: 100%;
  background-color: white;
  display: flex;

  @media (--m) {
    height: auto;
  }
}

.navItemWithChildren {
  display: inline-block;
}

.navDropdownBG {
  position: fixed;
  z-index: 1;
  background-color: rgb(0 0 0 / 20%);
  inset: 0;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(10px);
  }
}

.menuSignInOut {
  width: 100%;
  margin-top: 35px;
}
