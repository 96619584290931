.modal {
  max-width: 415px;

  div {
    max-height: fit-content;
    padding-bottom: 5px;
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.contentContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.vehicleImage {
  width: 220px;
}

.iconContainer {
  position: absolute;
  width: 42px;
  height: 42px;
  background-color: var(--ds-color-pureWhite);
  border-radius: 50%;
  translate: 90px -5px;
}

.authTitleAndText {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  gap: 8px;
  text-align: center;
}

.authTitleAndText > h2 {
  color: var(--ds-color-deepPurple);
  font-size: 1.5rem;
  font-weight: var(--ds-font-weight-bold);
}

.authTitleAndText > p {
  color: var(--cds-color-monochrome-black);
  font-size: 1rem;
  line-height: 1.5em;
}
