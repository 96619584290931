.listItem {
  padding-bottom: 24px;
  color: #311b77 !important;
  display: flex;
  @media (--m) {
    padding-bottom: 1rem;
  }
}

.largeMobileNavContainer {
  padding-bottom: 56px;
  @media (--m) {
    padding-bottom: 0;
  }
}

.shopByTypeContainer {
  display: grid;
  gap: 12px;
  grid-template-columns: auto auto;

  @media (--xxxs) {
    gap: 16px;
  }
  @media (--s) {
    gap: 0.75rem;
  }
}

.smallMobileContainer {
  padding-bottom: 32px;
}
