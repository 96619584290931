.authButtonsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1.25rem;
  gap: 0.75rem;
}

.authButton {
  padding-bottom: 5px;
}
