.mobileMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  background-color: white;
}

.mobileProfileWrapper {
  display: flex;
}

.mobileProfile {
  display: flex;
  margin-right: 1rem;
}

.mobileCloseButton {
  border: 0;
  margin-top: 5px;
  background-color: transparent;
}

.mobileLogo {
  margin-right: auto;
  display: flex;
  align-items: center;
  color: #8c32be;
}

.favouritesIconContainer {
  width: 50px;
}
