.container {
  padding-top: var(--ds-space-8-x);
  padding-bottom: calc(var(--ds-space-1-x) * 10);
  margin-bottom: var(--ds-space-7-x);

  @media (--s) {
    padding-top: var(--ds-space-6-x);
    margin-top: var(--ds-space-8-x);
  }
  @media (--m) {
    padding-top: 114px;
    padding-bottom: 148px;
    margin: 153px 0 107px;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  color: var(--ds-color-mineShaft);
  padding: 0 var(--ds-space-4-x) 0 var(--ds-space-4-x);
  font-size: var(--ds-font-size-xxl);
  text-align: center;

  @media (--s) {
    font-size: var(--ds-font-size-xl);
  }

  @media (--m) {
    font-size: var(--ds-font-size-3xl);
  }
}

.body {
  display: none;
  text-align: center;
  color: var(--ds-color-mineShaft);

  @media (--s) {
    display: block;
    font-size: var(--ds-font-size-m);
    padding-top: var(--ds-space-2-x);
    max-width: 610px;
  }

  @media (--m) {
    font-size: var(--ds-font-size-l);
    max-width: 763px;
  }
}

.cards {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--ds-space-2-x);
  padding-top: var(--ds-space-7-x);
  margin-left: var(--ds-space-2-x);
  margin-right: var(--ds-space-2-x);
  border-radius: 16px;

  @media (--s) {
    width: initial;
    margin: 0;
    padding-top: var(--ds-space-6-x);
  }

  @media (--m) {
    padding-top: var(--ds-space-7-x);
    flex-direction: row;
    justify-content: center;
  }
}
