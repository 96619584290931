/* stylelint-disable scale-unlimited/declaration-strict-value */
.container {
  display: flex;
  align-content: stretch;
  padding-bottom: 3rem;

  @media (--m) {
    padding-bottom: 2rem;
  }
}

.containerWithoutImage {
  min-width: 18.5rem;
  padding-bottom: 2.5rem;

  @media (--s) {
    min-width: 325px;
    padding-bottom: 3rem;
  }
  @media (--m) {
    width: 19.5rem;
    padding-bottom: 3.5rem;
    margin-right: 5.5rem;
  }
}

.containerWithSmallImage {
  padding: 16px 0;
  border-bottom: 1px solid #e1dafb;
}

.containerWithSmallImage:nth-last-child(2) {
  border: none;
}

.containerWithSmallImage:nth-child(1) {
  padding-top: 0;
}

.containerWithSmallImage:nth-last-child(1) {
  border: none;
}

.textContainer {
  @media (--m) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.heading {
  padding-bottom: 0.25rem;
  color: #8f1dbf !important;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.625rem;
  @media (--m) {
    padding-bottom: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.snippet,
.date {
  color: #656565;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.625rem;
  @media (--m) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.link {
  display: flex;
  align-items: flex-start;
  color: #1565c0 !important;
  font-size: 1.125rem;
  line-height: 1.625rem;

  @media (--m) {
    font-size: 1rem;
  }
}

.linkHeading {
  padding-bottom: 0.25rem;
  color: #2f2f2f !important;
  font-size: 1.125rem;
  line-height: 1.625rem;
  @media (--m) {
    padding-bottom: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.icon {
  padding-left: 0.75rem;

  @media (--xs) {
    padding-top: 2px;
  }
}

.customIcon {
  margin-top: 2px;
}

.imageContainer {
  margin-right: 24px;
}

.image {
  width: 100%;
  border-radius: 0.5rem;
}

.imageContainerSmall {
  width: 84px;
  min-width: 84px;
  height: 48px;
  margin-right: 12px;
}

.date {
  font-size: 1rem;
}

.linkText {
  @media (max-width: 320px) {
    width: 160px;
  }
}
