.card {
  border-radius: 16px;
  background-color: var(--ds-color-pureWhite);
  padding: var(--ds-space-3-x);

  @media (--m) {
    padding: var(--ds-space-4-x);
  }
}

.cardBoxShadow {
  box-shadow: 0 var(--ds-space-half-x) var(--ds-space-1-and-a-half-x)
    rgb(var(--ds-color-pureBlack-rgb) 0.2);
}

.cardBorder {
  border: 2px solid var(--ds-color-gainsboro);
}
