@value variables: '../../../../../variables.css';
@value layout-container-border-radius from variables;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--ds-color-pureWhite);
  padding: 0 var(--ds-space-3-x);

  @media (--m) {
    height: calc(418px + layout-container-border-radius);
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.singleTitleWrapper {
  display: flex;
  align-items: center;
  color: var(--ds-color-pureWhite);
  height: 194px;

  @media (--m) {
    height: 210px;
  }
}

.title {
  line-height: 42px;
  text-align: center;

  @media (--m) {
    font-size: 42px;
    line-height: 52px;
    text-align: left;
  }
}

.heroContent {
  text-align: center;
  padding-top: var(--ds-space-1-x);

  @media (--m) {
    text-align: left;
    width: 65%;
  }
}

.body {
  margin-top: var(--ds-space-1-x);
  margin-bottom: calc(54px + layout-container-border-radius);
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  @media (--s) {
    max-width: 494px;
    margin-bottom: calc(80px + layout-container-border-radius);
  }

  @media (--m) {
    max-width: 520px;
    margin-top: var(--ds-space-2-x);
    margin-bottom: 0;
    font-size: var(--ds-space-3-x);
    line-height: 34px;
    text-align: left;
  }
}

.imageWrapper {
  position: relative;
  width: 280px;
  height: 194px;
  margin-top: 51px;
  margin-bottom: 26px;

  @media (--m) {
    width: 300px;
    height: 208px;
    margin: 0;
  }
}

/* Styles for APP page */

.appRoot {
  flex-direction: column-reverse;

  @media (--s) {
    height: calc(418px + layout-container-border-radius);
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.appTitle {
  margin-top: var(--cds-spacing-large-rem-300);

  @media (--s) {
    font-size: 42px;
    line-height: 52px;
    text-align: left;
  }
}

.appBody {
  margin-bottom: var(--cds-spacing-medium-rem-300);

  @media (--s) {
    text-align: left;
  }
}

.appImageWrapper {
  width: 400px;
  height: 300px;
  margin-bottom: calc(54px + layout-container-border-radius);
  margin-top: var(--cds-spacing-medium-rem-300);

  @media (--s) {
    width: 300px;
    height: 350px;
    margin: 0;
    margin-top: var(--cds-spacing-large-rem-300);
  }
}

.appHeroButtons {
  display: flex;
  justify-content: space-around;
  gap: var(--cds-spacing-medium-rem-100);

  @media (--s) {
    justify-content: flex-start;
    gap: var(--cds-spacing-medium-rem-200);
  }
}
