.backdrop {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 50%);
  font-family: 'Buenos Aires', system-ui, sans-serif;
}

.backdropEnter {
  opacity: 0;

  &.backdropEnterActive {
    opacity: 1;
    transition: opacity 250ms;
  }
}

.backdropExit {
  opacity: 1;

  &.backdropExitActive {
    opacity: 0;
    transition: opacity 250ms;
  }
}

.modal {
  width: 450px;
  max-width: 100vw;
  max-height: 100vh;
}

.modalEnter {
  opacity: 0;
  transform: translate3d(0, 100px, 0);

  &.modalEnterActive {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 250ms;
  }
}

.modalExit {
  opacity: 1;
  transform: translate3d(0, 0, 0);

  &.modalExitActive {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    transition: all 250ms;
  }
}
