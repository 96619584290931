.yourProfileContainer {
  display: flex;
  flex-flow: column nowrap;
}

.profilePageLinkContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 48px;
  margin-left: 44px;
}

.profilePageLinkTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--cds-color-monochrome-black);
  margin-left: 16px;
}

.circle {
  width: 20px;
  height: 20px;
  background: linear-gradient(to right, #67efeb, #8bfcc1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ds-color-vividViolet);
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 8px rgba(92 92 92 41.1%);
  margin-top: 4px;
  margin-left: 10px;
}
