.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: 100%;
  color: var(--ds-color-mineShaft);

  @media screen and (--m) {
    flex-direction: row;
    gap: var(--ds-space-1-x);
  }
}

.boldText {
  font-weight: var(--ds-font-weight-bold);
}

.firstLine {
  display: inherit;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  text-wrap: nowrap;

  @media screen and (--m) {
    width: 50%;
    justify-content: end;
  }
}

.secondLine {
  display: inherit;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: var(--ds-space-1-x);
  text-wrap: nowrap;

  @media screen and (--m) {
    width: 50%;
    justify-content: start;
  }

  svg {
    padding-bottom: 2px;
    width: 80px;
  }
}

.singleLine {
  width: 100%;
  @media screen and (--m) {
    justify-content: center;
  }
}

.logo {
  max-height: 22px;
}
