.container {
  display: flex;
  height: 68px;
  align-items: center;
  justify-content: center;
  padding: 17px 14px;
  background-color: var(--ds-color-morningFrost);

  @media (--s) {
    height: 70px;
    padding: 0 var(--ds-space-3-x);
  }
}

.depositContributionContainer {
  padding: 42px 14px;

  @media (--s) {
    padding: 0 var(--ds-space-3-x);
  }
}

.containerInfo {
  height: unset;
  @media (--s) {
    height: 70px;
  }
}
