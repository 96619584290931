.container {
  display: block;
  line-height: 0;
}

.gradient {
  position: absolute;
  width: 0;
  height: 0;
}
