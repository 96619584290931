.sectionTitle {
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-xl);
  line-height: var(--ds-space-4-x);

  @media (--s) {
    margin-bottom: var(--ds-space-5-x);
  }

  @media (--m) {
    font-size: var(--ds-font-size-xxl);
  }
}

.list {
  column-count: 2;
  list-style-type: none;

  @media (--s) {
    column-count: 4;
  }
}

.listItem {
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-vividViolet);
  text-decoration: underline;
}
