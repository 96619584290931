.root {
  margin-bottom: var(--ds-space-5-x);
}

.reviewsTitle {
  color: var(--ds-color-deepPurple);
  font-weight: var(--ds-font-weight-bold);
  font-size: var(--ds-font-size-xxl);
  line-height: 40px;
  margin-bottom: var(--ds-space-2-x);

  @media (--m) {
    font-size: var(--ds-font-size-3xl);
    line-height: 48px;
    margin-bottom: var(--ds-space-4-x);
  }
}

.trustPilotReviewsDesktopWrapper {
  display: flex;
  justify-content: center;
  grid-column-gap: var(--ds-space-2-x);

  @media (--m) {
    padding-bottom: var(--ds-space-7-x);
  }
}

.trustPilotLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trustpilotLinkText {
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-xs);
  font-weight: var(--ds-font-weight-medium);
}

.logo {
  height: 20px;
  margin-left: var(--ds-space-2-x);
}

.controlsWrapper {
  margin-top: var(--ds-space-4-x);
  text-align: center;
}

/* stylelint-disable selector-class-pattern */
.carouselPagination:global(.trustpilot-carousel-pagination
    > .swiper-pagination-bullet) {
  border-color: var(--ds-color-deepPurple);
}

.carouselPagination:global(.trustpilot-carousel-pagination
    > .swiper-pagination-bullet-active) {
  background: var(--ds-color-deepPurple);
}

.carouselPrevButton,
.carouselNextButton {
  color: var(--ds-color-deepPurple);
}
