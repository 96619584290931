.container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.tag {
  --background: #f5f5f5;

  height: 100%;
  padding: 0.25rem 0.5rem;
  background-color: var(--background);
  border-radius: 1.5rem;
}

.tagText {
  --text-color: #311c77;

  color: var(--text-color);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.55;
  text-wrap: nowrap;
}
