.list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: var(--ds-space-2-x);
  list-style: disc;
  list-style-position: inside;
}

.item {
  position: relative;
  margin-right: 10px;
  color: var(--ds-color-dove);

  &::marker {
    color: var(--ds-color-iceBlue);
  }
}

.upperCase {
  text-transform: capitalize;
}
