/* stylelint-disable scale-unlimited/declaration-strict-value */
.listWrapperContainer {
  color: #8f1dbf;
  font-size: 1.25rem;
  line-height: 1.5rem;

  @media (--s) {
    height: auto;
    padding-left: 0;
    font-size: 1rem;
  }
}

.heading {
  padding-top: 1rem;
  padding-bottom: 16px;
  color: #8f1dbf;
  font-size: 18px;
  font-weight: 700;

  @media (--m) {
    padding-bottom: 12px;
    font-size: 16px;
  }
  @media (--s) {
    padding-top: 0;
    font-size: 1rem;
    line-height: 24px;
  }
}

.listCardHeading {
  padding-bottom: 0.875rem;
  color: #8f1dbf;
  font-size: 18px;
  font-weight: 700;

  @media (--s) {
    padding-bottom: 12px;
    line-height: 24px;
  }
}

.wrapperLink {
  display: flex;
  align-items: center;
  color: #1565c0 !important;
  font-size: 1.125rem;
  text-align: center;

  @media (--m) {
    font-size: 1rem;
  }
}

.listCardWrapperLink {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  color: #1565c0 !important;
  font-size: 18px;

  @media (max-width: 320px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  @media (--m) {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}

.wrapperLinkIcon {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  @media (max-width: 320px) {
    padding-top: 4px;
  }
}

.listCardUrlText {
  @media (max-width: 320px) {
    width: 58%;
  }
}
