.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}

.price {
  --price-text: #2f2f2f;

  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.55;
  color: var(--price-text);
}

.discounted {
  --discount-text: #d90700;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.55;
  color: var(--discount-text);
}
