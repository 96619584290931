.sectionTitle {
  margin-top: var(--ds-space-7-x);
  margin-bottom: var(--cds-spacing-medium-rem-100);
  font-size: var(--ds-font-size-xl);

  @media (--m) {
    margin-top: var(--cds-spacing-large-rem-100);
  }
}

.faqSection {
  margin-bottom: 40px;

  @media (--s) {
    margin-bottom: var(--ds-space-7-x);
  }
  @media (--m) {
    margin-bottom: 71px;
  }
}
