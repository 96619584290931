/* stylelint-disable selector-class-pattern */

.controls {
  display: flex;
  justify-content: space-between;
  align-content: center;
  pointer-events: all;
}

.controlsBtnPrev {
  left: var(--ds-space-2-x);

  @media (--m) {
    left: var(--ds-space-4-x);
  }
}

.controlsBtnNext {
  right: var(--ds-space-2-x);

  @media (--m) {
    right: var(--ds-space-4-x);
  }
}

.controlsBtn {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  color: var(--ds-color-deepPurple);
  background: transparent;
  border: 1px solid var(--ds-color-deepPurple);
  border-radius: 50%;
  cursor: pointer;

  @media (--m) {
    margin: 0 -35px;

    :hover:not([disabled]) {
      border: 2px solid var(--ds-color-deepPurple);
      color: var(--ds-color-deepPurple);
    }
  }

  &:global(.swiper-button-disabled) {
    cursor: not-allowed;
    border: 1px solid var(--ds-color-gainsboro);
    color: var(--ds-color-gainsboro);
  }
}
