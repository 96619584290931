.container {
  padding: 0.75rem 0;
}

.button {
  --color: #2f2f2f;
  --hover-color: #8c32be;

  color: var(--color);
  font-size: 1rem;
  font-weight: 400;
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: var(--hover-color);
  }
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.drawerText {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.55;
}
