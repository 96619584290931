.loadingSpinner {
  display: block;
  margin: auto;
  animation: spinner-rotation 2s linear infinite;
  fill: none;
  stroke-width: 4;
}

.foregroundCircle {
  animation: foreground-dash 1600ms 50ms cubic-bezier(0.77, 0, 0.175, 1)
    infinite;
  stroke-dasharray: 69;
  stroke-dashoffset: -69;
  transform-origin: 50%;
}

@media all and (-ms-high-contrast: none) {
  .foregroundCircle {
    stroke-dashoffset: -40;
  }
}

@keyframes spinner-rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes foreground-dash {
  0%,
  3% {
    stroke-dashoffset: -69;
  }

  47%,
  53% {
    stroke-dashoffset: 0;
  }

  97%,
  100% {
    stroke-dashoffset: 69;
  }
}
