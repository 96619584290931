.borderLeftRadius {
  border-bottom-left-radius: var(--ds-space-2-x);
  border-top-left-radius: var(--ds-space-2-x);

  @media (--m) {
    border-bottom-left-radius: var(--ds-space-3-x);
    border-top-left-radius: var(--ds-space-3-x);
  }
}

.borderRightRadius {
  border-bottom-right-radius: var(--ds-space-2-x);
  border-top-right-radius: var(--ds-space-2-x);

  @media (--m) {
    border-bottom-right-radius: var(--ds-space-3-x);
    border-top-right-radius: var(--ds-space-3-x);
  }
}

.panel {
  composes: borderLeftRadius borderRightRadius;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: var(--ds-color-pureWhite);
  box-shadow: inset 0 0 0 2px var(--ds-color-gainsboro);
  transition: box-shadow 200ms ease-in-out;
}

.low {
  box-shadow: inset 0 0 0 2px var(--ds-color-gainsboro);
}

.mid {
  box-shadow: 0 var(--ds-space-half-x) var(--ds-space-1-x) 0 rgba(0 0 0 / 20%);
}

.top {
  box-shadow: 0 var(--ds-space-2-x) var(--ds-space-4-x) 0 rgba(0 0 0 / 41%);
}

.bodyContent {
  width: 100%;
  flex: 1 1 auto;
  padding: var(--ds-space-4-x);
}

.bodyFlex {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: var(--ds-space-2-x);
  color: var(--ds-color-deepPurple);
}

.noPadding {
  padding: 0;
  box-shadow: none;
}

.addornmentLeft {
  composes: borderLeftRadius;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100px;
  align-items: center;
  justify-content: center;
  border-right: 2px solid var(--ds-color-gainsboro);
  margin: 20px 0;
}
