.cardContainer {
  position: relative;
  width: 311px;
  max-height: 100vh;
  box-sizing: border-box;
  background-clip: padding-box;
  background-color: var(--ds-color-pureWhite);
  border-radius: var(--ds-space-4-x);
  box-shadow: 0 20px 28px rgb(16 7 44 / 30%);
}

.cardTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ds-space-2-x) var(--ds-space-3-x) 0;
  border: none;
  margin: 0;
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-m);
}

.cardBody {
  --title-height: 66px;

  overflow: auto;
  max-height: calc(100vh - var(--title-height));
  padding: 0 var(--ds-space-3-x) var(--ds-space-3-x);
}

.cardCloseIcon {
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  color: var(--ds-color-deepPurple);
  cursor: pointer;
}

.modal {
  max-width: 100vw;
  max-height: 100vh;
}

.backdrop {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 50%);
  font-family: 'Buenos Aires', system-ui, sans-serif;
}

.backdropStyle {
  display: flex;
  align-items: flex-start;
  padding-top: 75px;

  @media (--m) {
    position: absolute;
    justify-content: flex-end;
    padding-right: 40px;
    background-color: var(--bgtransparent);
  }

  @media screen and (min-width: 1280px) {
    padding-right: calc(40px + (100vw - 1280px) / 2);
  }
}

.priceWrapper {
  display: flex;
  justify-content: space-between;
}

.price {
  padding: var(--ds-space-2-x) 0 var(--ds-space-3-x);
  line-height: 26px;
}

.adminFee {
  padding: var(--ds-space-2-x) 0 var(--ds-space-3-x);
  line-height: 24px;
}

.vehicleImage,
.vehicleImageTransparent {
  padding: 0 var(--ds-space-4-x);
  margin: var(--ds-space-2-x) 0;
  border-radius: 0 0 20% 20%;
}

.vehicleImageTransparent {
  filter: grayscale(1);
  opacity: 0.5;
}

.vehicleInfoContainerTransparent {
  color: var(--ds-color-dove);
}

.loadingDiv {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.subheader {
  display: inline;
}

.remainingTimeText {
  text-align: center;
}

.expiredUnavailableText {
  margin: var(--ds-space-3-x) 0;
}

.titleWithIconContainer {
  display: flex;
  align-items: center;
}

.timeIcon {
  width: 24px;
  height: 24px;
  margin-right: var(--ds-space-1-x);
}

.cardTitle {
  margin-bottom: var(--ds-space-1-x);
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-s);
}

.button {
  margin-top: var(--ds-space-2-x);
}

.underlineText:hover {
  text-decoration: underline;
}
