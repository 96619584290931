/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable scale-unlimited/declaration-strict-value */
.reviewCardContainer {
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--m) {
    height: 400px;
    box-shadow: 0 30px 60px 0 #a9a9a94d;
    border-radius: var(--ds-space-2-x);
    padding: 56px 32px 32px;
    width: 320px;
  }

  @media (--l) {
    width: 341px;
  }
}

.trustPilotTitle {
  color: var(--ds-color-mineShaft);
  margin-bottom: var(--ds-space-2-x);

  @media (--m) {
    font-size: var(--ds-font-size-m) !important;
    line-height: 26px !important;
  }
}

.trustPilotDescription {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--ds-color-mineShaft);
  font-size: 20px;

  @media (--m) {
    -webkit-line-clamp: 4;
    font-size: var(--ds-font-size-m);
    flex-grow: 1;
    width: auto;
  }
}

.trustPilotCustomerName {
  color: var(--ds-color-mineShaft);
}

.trustPilotDate {
  color: var(--ds-color-mercury);
}

.starRatingContainer {
  padding-bottom: var(--ds-space-3-x);
  text-align: left;
  display: flex;
  @media (--m) {
    justify-content: flex-start;
  }
}

.starRatingIconContainer {
  width: 31px;
  height: 31px;
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcdce6;
}

.starRatingIconContainerFilled {
  background-color: #00b67a;
}

.starRatingIcon {
  height: 21px;
  width: 21px;
}

a.trustpilotReviewLink {
  text-decoration: none;
}

a.trustpilotReviewLink > * {
  white-space: normal;
}
