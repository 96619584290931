.card {
  position: relative;
  width: 311px;
  max-height: 100vh;
  box-sizing: border-box;
  background-clip: padding-box;
  background-color: var(--ds-color-pureWhite);
  border-radius: var(--ds-space-4-x);
  box-shadow: 0 20px 28px rgb(16 7 44 / 30%);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ds-space-2-x) var(--ds-space-3-x) 0;
  border: none;
  margin: 0;
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-m);
}

.subtitle {
  display: block;
  color: var(--ds-color-dove);
  font-weight: var(--ds-font-weight-medium);
}

.close {
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  cursor: pointer;
}

.textButton > div {
  min-width: 90px;
  padding: 4px !important;
}

.body {
  --title-height: 66px;

  overflow: auto;
  max-height: calc(100vh - var(--title-height));
  padding: 0 var(--ds-space-3-x) var(--ds-space-3-x);
}
