.saveTag {
  padding: 6px var(--ds-space-1-x);
  margin-right: var(--ds-space-2-x);
  background-color: var(--ds-color-deepPurple);
  border-radius: 4px;
  color: var(--ds-color-iceBlue);
  font-size: var(--ds-font-size-xs);
  font-weight: var(--ds-font-weight-bold);
}

.text {
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-xs);
  line-height: 20px;

  a {
    color: var(--ds-color-toreaBay);
    text-decoration: underline;
  }

  @media (--s) {
    font-size: var(--ds-font-size-s);
  }
}

.text > a {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: inherit;
}
