.userProfileWrapper {
  display: none;

  @media (--m) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.profileLink {
  display: flex;
  align-items: center;
}

.profileLinkText {
  padding-right: 0.75rem;
  font-weight: 600;
}
