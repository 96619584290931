/* stylelint-disable scale-unlimited/declaration-strict-value */
.buttonContainer {
  position: fixed;
  z-index: 10;
  right: 16px;
  bottom: 64px;

  button {
    width: 48px;
    min-width: auto;
    height: 48px;
    padding: 8px;
    border: 2px #57e8ff solid;
    background-color: #8f1dbf;
    border-radius: 50%;

    @media (--s) {
      width: 64px;
      height: 64px;
    }
  }

  @media (--s) {
    bottom: 16px;
  }
}

.commentIcon {
  width: 20px;
  height: 16px;

  @media (--s) {
    width: 25px;
    height: 20px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
