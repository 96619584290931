.text {
  font-weight: 400;
  line-height: 20px;
  font-size: var(--ds-font-size-xs);
  padding-left: var(--ds-space-2-x);
  color: var(--ds-color-mineShaft);

  @media (--s) {
    line-height: var(--ds-space-3-x);
    font-size: var(--ds-font-size-s);
  }
}

.link {
  font-weight: var(--ds-font-weight-bold);
  text-decoration: underline;
}

.svg {
  width: var(--ds-space-5-x);
  height: var(--ds-space-5-x);

  @media (--s) {
    width: var(--ds-space-3-x);
    height: var(--ds-space-3-x);
  }
}
