/* stylelint-disable scale-unlimited/declaration-strict-value */
.adminFeeInfoButton {
  color: #2f2f2f;
  text-align: left;
  text-decoration: underline;
  border: none;
  padding-bottom: 0.75rem;
  margin-top: 0.75rem;
  background: none;
  cursor: pointer;
  z-index: 1;
}

.adminFeeInfoButton:hover,
.adminFeeInfoButton:focus {
  color: #8c32be;
}
