.basketBannerVehicleInfoContainer {
  display: flex;
  flex-direction: row;
  padding: var(--ds-space-1-x) 0;

  .vehicleInfo {
    overflow: hidden;
    flex-basis: 75%;
    padding-right: var(--ds-space-1-x);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .price {
    flex-basis: 25%;
    align-self: center;
    padding-left: var(--ds-space-1-x);
    font-weight: var(--ds-font-weight-bold);
    text-align: right;
  }

  @media (--l) {
    padding: var(--ds-space-1-x) var(--ds-space-3-x);

    .vehicleInfo,
    .price {
      overflow: auto;
      flex-basis: 100%;
      align-self: start;
      padding: 0;
      text-align: left;
      text-overflow: initial;
      white-space: normal;
    }

    .price {
      align-self: center;
      text-align: right;
    }
  }

  @media (--s) {
    padding: var(--ds-space-1-x) var(--ds-space-3-x);

    .vehicleInfo,
    .price {
      overflow: auto;
      flex-basis: 100%;
      align-self: start;
      padding: 0;
      text-align: left;
      text-overflow: initial;
      white-space: normal;
    }

    .price {
      flex: 1 1 auto;
      align-self: center;
      text-align: right;
    }
  }
}

.basketBannerVehicleInfo {
  display: flex;
  flex-direction: column;

  @media (--s) {
    padding-right: var(--ds-space-4-x);
  }

  @media (--l) {
    width: 475px;
    padding-right: 0;
  }
}

.vehicleImage {
  width: 140px;
  min-height: 78px;
  max-height: 80px;
  border-radius: 8px;
  text-align: center;

  p {
    font-size: var(--ds-font-size-xs);
  }
}

.vehicleImageWithAdminFee {
  width: 220px;
  min-height: 126px;
  max-height: 126px;
}

.vehicleImageLink {
  min-width: 220px;
}

.vehicleImageMobile {
  max-width: 128px;
  margin-top: 8px;
  margin-bottom: 16px;
  border-radius: 8px;
}

.bannerHeading {
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-l);
  font-weight: var(--ds-font-weight-bold);
}

.basketBannerVehicleInfoContainerWithAdminFee {
  flex-direction: column;

  @media (--s) {
    padding: var(--ds-space-1-and-a-half-x) var(--ds-space-3-x);
  }
}

.priceRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--ds-space-1-and-a-half-x);
}

.priceRowWithAdminFee {
  @media (--s) {
    padding: var(--ds-space-2-x) 0;
    border-top: solid 1px var(--ds-color-gainsboro);
  }
}

.underlineText:hover {
  text-decoration: underline;
}

.vehicleLink {
  max-width: 75%;
}
