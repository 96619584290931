/* stylelint-disable scale-unlimited/declaration-strict-value */
.listItem {
  color: #311b77;
  font-size: 1.125rem;
  line-height: 1.625rem;

  @media (--m) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
