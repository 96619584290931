.imgContainer {
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: var(--ds-color-gainsboro);

  @supports (aspect-ratio: 16/9) {
    aspect-ratio: 16/9;
  }

  @supports not (aspect-ratio: 16/9) {
    padding-top: 56.25%;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @supports (aspect-ratio: 16/9) {
    min-height: auto;
  }

  @supports not (aspect-ratio: 16/9) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.unavailable {
  filter: blur(10px);
}
