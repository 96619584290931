.heading {
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-pampas);

  @media (--m) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subHeading,
.linkLabel {
  display: block;
  margin-top: var(--ds-space-3-x);
  margin-bottom: var(--ds-space-3-x);

  @media (--m) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: var(--ds-space-1-and-a-half-x);
    font-size: var(--ds-font-size-m);
  }
}

.linkLabel {
  text-decoration: underline;
  text-underline-position: under;
}

.questionMarkIcon {
  display: block;
  margin: 0 auto;

  @media (--m) {
    display: initial;
    margin: initial;
    margin-right: var(--ds-space-1-x);
  }
}

.arrowRightIcon {
  position: relative;
  top: 6px;
  margin-left: var(--ds-space-1-x);
}

.verticalLine {
  position: absolute;
  left: -200%;
  margin-right: var(--ds-space-2-x);
  margin-left: var(--ds-space-3-x);

  @media (--m) {
    position: initial;
    display: block;
  }
}
