.holdingImage {
  display: flex;
  min-height: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-color-gainsboro);
}

.icon {
  color: var(--ds-color-asphaltGrey);
}
