.heading {
  padding-top: var(--ds-space-4-x);
  padding-bottom: var(--ds-space-2-x);
}

.videoContainer {
  padding: 0;
}

.video {
  position: relative;
  left: 50%;
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  margin-bottom: var(--ds-space-7-x);
  margin-left: -50vw;
  border-radius: 0%;

  @media (--s) {
    left: 0%;
    width: auto;
    margin-bottom: calc(var(--ds-space-1-x) * 9);
    margin-left: auto;
  }
}

.video img {
  border-radius: 0%;
  object-fit: cover;

  @media (--s) {
    border-radius: 32px;
  }
}

.descriptionVideoMargin {
  margin-top: var(--ds-space-4-x);
}

.videoMargin {
  margin-top: calc(var(--ds-space-1-x) / 2 * 10);
}
