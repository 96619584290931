/* stylelint-disable selector-class-pattern */

.carouselWrapper {
  position: relative;
  width: 100%;

  :global(.swiper-pagination) {
    width: 100%;
    bottom: 16px;
  }

  :global(.swiper-pagination-bullet) {
    margin: 0 var(--ds-space-1-x);
    border: 1px solid var(--ds-color-deepPurple);
    background: none;
    opacity: 1;
    transition: 0.2s transform, 0.2s left;
  }

  :global(.swiper-pagination-bullet-active) {
    background: var(--ds-color-deepPurple);
  }

  :global(.swiper-pagination-bullets-dynamic) {
    white-space: nowrap;
  }

  :global(.swiper-container) {
    overflow: hidden;
  }

  /* rendering fix for local */
  :global(.swiper-wrapper) {
    display: flex;
  }

  /* rendering fix for local */
  :global(.swiper-slide) {
    flex-shrink: 0;
  }
}

.showCarousel {
  visibility: visible;
}
