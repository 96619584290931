.trustpilotWidgetContainer {
  position: relative;
}

.linkOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}
