.links {
  padding-bottom: var(--ds-space-1-x);
  margin-top: var(--ds-space-5-x);

  @media (--m) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: var(--ds-space-8-x);
  }
}

.link {
  margin-bottom: var(--ds-space-3-x);
  white-space: nowrap;

  @media (--m) {
    margin-right: var(--ds-space-3-x);
    margin-bottom: 20px;
    margin-left: var(--ds-space-3-x);
  }
}
