.item {
  svg {
    width: 32px;
    height: 32px;
    fill: currentcolor;
  }

  @media (--m) {
    padding-right: var(--ds-space-5-x);
    padding-left: var(--ds-space-5-x);

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 75%;
      background-color: var(--components-footer-base-color);
      content: '';
      opacity: 0.3;
      transform: translateY(-50%);
    }

    &:last-child::after {
      display: none;
    }
  }
}

.link {
  display: block;
}

.iconName,
.linkLabel {
  position: absolute;
  left: -200%;
  @media (--m) {
    position: initial;
    display: block;
  }
}

.linkLabel {
  text-decoration: underline;
  text-underline-position: under;
}

.body {
  margin-bottom: var(--ds-space-2-x);

  @media (--m) {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: var(--ds-space-2-x);
    grid-template-columns: auto auto;
  }

  &:not(:focus, :active) {
    @media (--m) {
      position: static;
      width: auto;
      height: auto;
      clip-path: none;
      font-size: var(--ds-font-size-m);
      visibility: visible;
      white-space: initial;
    }
  }
}
