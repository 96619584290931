.wrapper {
  position: relative;
  width: 100%;
}

.container {
  max-width: var(--ds-dimensions-max);
  padding-right: calc(var(--ds-space-2-x) + env(safe-area-inset-right));
  padding-left: calc(var(--ds-space-2-x) + env(safe-area-inset-left));
  margin-right: auto;
  margin-left: auto;

  @media (--m) {
    padding-right: calc(var(--ds-space-4-x) + env(safe-area-inset-right));
    padding-left: calc(var(--ds-space-4-x) + env(safe-area-inset-left));
  }
}

.slimContainer {
  max-width: var(--ds-dimensions-slim);
}

.wrapperFullHeight {
  flex: 1 1 auto;

  @media all and (-ms-high-contrast: none) {
    height: 100%;
  }
}

.wrapperOverflow {
  overflow: visible;
}
