.root {
  color: var(--ds-color-mineShaft);
  font-size: 28px;
  line-height: var(--ds-space-5-x);

  @media (--m) {
    font-size: 34px;
    line-height: var(--ds-space-6-x);
  }
}
