.root {
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-m);
  line-height: 26px;

  @media (--m) {
    font-size: var(--ds-font-size-l);
    line-height: var(--ds-space-4-x);
  }
}
