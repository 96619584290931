.button {
  display: flex;
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  padding: var(--ds-space-half-x);
  border: none;
  margin-left: var(--ds-space-1-x);
  background-color: var(--ds-color-wildSand);
  color: inherit; /* stylelint-disable-line */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:focus {
    box-shadow: 0 0 0 3px var(--ds-color-iceBlue);
    outline: none;
  }

  @media (hover) {
    &:hover {
      transform: scale(1.2);
    }
  }
}

.loading {
  color: rgb(0 0 0 / 0%);
}
