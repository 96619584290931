.imageContainer {
  position: relative;
  display: flex;
  overflow: hidden;

  @supports (aspect-ratio: 16/9) {
    aspect-ratio: 16/9;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
