.fullWidthCta {
  position: relative;
  overflow: hidden;
  left: 50%;
  display: flex;
  width: 100vw;
  justify-content: center;
  margin: var(--ds-space-4-x) 0 0 -50vw;
  background-color: var(--ds-color-water);
  @media (--m) {
    height: 434px;
  }
}

.inner {
  display: flex;
  position: relative;
  padding: var(--ds-space-8-x) var(--ds-space-6-x);
  width: 100%;
  flex-direction: column;

  @media (--s) {
    flex-direction: row;
    padding: 80px var(--ds-space-4-x);
    max-width: 960px;
  }

  @media (--m) {
    padding: var(--ds-space-6-x) 0;
    width: calc(var(--ds-breakpoint-m-min) * 1px);
    align-items: center;
  }
}

.button {
  background-color: var(--ds-color-toreaBay);
  margin-top: var(--ds-space-3-x);
  @media (--s) {
    margin-top: 0;
  }
  @media (--m) {
    margin-top: var(--ds-space-4-x);
  }
}

.svg {
  margin: var(--ds-space-1-x) var(--ds-space-2-x) var(--ds-space-2-x) 0;
  display: block;
  @media (--s) {
    display: none;
  }
  @media (--m) {
    display: none;
  }
}

.content {
  flex-direction: column;
  color: var(--ds-color-deepPurple);

  @media (--s) {
    margin-bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-around;
    align-items: center;
  }

  @media (--m) {
    display: block;
  }
}

.subtitle {
  font-size: var(--ds-font-size-m);
  margin-bottom: var(--ds-space-half-x);
  color: var(--ds-color-deepPurple);

  @media (--m) {
    font-size: var(--ds-font-size-l);
  }
}

.title {
  line-height: 1;
  font-size: var(--ds-font-size-xxl);
  color: var(--ds-color-deepPurple);
  font-weight: var(--ds-font-weight-bold);

  @media (--m) {
    font-size: var(--ds-font-size-3xl);
  }
}

.img {
  display: none;

  @media (--m) {
    position: absolute;
    display: block;
    transform: translateX(68%);
  }
}

.innerButtonRight {
  @media (--s) {
    padding: var(--ds-space-8-x) var(--ds-space-4-x);

    .title,
    .subtitle {
      width: 55%;
    }

    .button {
      padding: var(--ds-space-1-x) 0;
      margin-top: 0;
      width: 30%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  @media (--m) {
    padding: 80px 0;

    .button {
      width: 300px;
    }
  }
}
