/* stylelint-disable color-function-notation */
.statsWrapper {
  padding-bottom: 64px;
  overflow-x: hidden;

  @media (--m) {
    overflow-x: visible;
  }
}

.carSectionTitle {
  margin-bottom: 80px;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.statsButton {
  width: 100%;
  max-width: 350px;

  @media (--s) {
    max-width: 320px;
  }

  @media (--m) {
    max-width: 350px;
  }
}

.stat {
  width: 100%;
  margin-bottom: 80px;

  @media (--m) {
    width: 50%;
    margin-bottom: var(--ds-space-12-x);
  }
}

.stat:nth-child(3) {
  width: 100%;
  max-width: 275px;
  margin-bottom: var(--ds-space-12-x);

  @media (--xs) {
    max-width: none;
  }

  @media (--xl) {
    width: 50%;
  }
}

.stat:nth-child(4) {
  margin-bottom: var(--ds-space-4-x);

  @media (--s) {
    margin-bottom: var(--ds-space-5-x);
  }
}

.statInner {
  max-width: 410px;
}

.statHeading {
  font-size: 92px;
  line-height: 1;
  background: rgb(79, 217, 251);
  background: linear-gradient(
    350deg,
    rgba(79, 217, 251) 0%,
    rgba(121, 71, 193) 45%
  );
  background-clip: text;
  -webkit-text-fill-color: var(--ds-color-transparent);
  display: inline-block;
  margin-bottom: var(--ds-space-1-and-a-half-x);
}

.statSubHeading {
  color: var(--ds-color-meteorite);
  margin-bottom: var(--ds-space-half-x);
}

.statImage {
  position: absolute;
  right: -350px;
  bottom: 125px;
  max-width: 550px;
  width: 550px;
  height: 235px;

  @media (--xxxs) {
    right: -300px;
  }

  @media (--xs) {
    bottom: 65px;
    max-width: 650px;
    width: 650px;
    height: 277px;
  }

  @media (--s) {
    bottom: 50px;
    max-width: 750px;
    width: 750px;
    height: 320px;
  }

  @media (--m) {
    max-width: none;
    right: -800px;
    bottom: -50px;
    width: 1400px;
    height: 597px;
  }

  @media (--xl) {
    display: none;
  }
}
