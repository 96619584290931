.preferencesSection {
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-dove);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.preferencesSectionHeader {
  display: flex;
  align-items: center;
  margin-bottom: var(--ds-space-1-x);
}

.preferencesSectionHeading {
  margin-top: var(--ds-space-half-x);
}

.preferencesBody {
  margin-bottom: var(--ds-space-half-x);
}
