/* stylelint-disable scale-unlimited/declaration-strict-value */
.carCareNavCard {
  @media (--m) {
    width: 311px;
  }
}

.list {
  @media (--l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
