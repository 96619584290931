.card {
  height: 360px;
  width: 100%;
  padding: 70px 42px 0;
  border-radius: var(--ds-space-2-x);
  background-color: var(--ds-color-pureWhite);

  @media (--s) {
    width: 524px;
    height: 298px;
    padding: 60px var(--ds-space-6-x) 0 var(--ds-space-6-x);
  }

  @media (--m) {
    width: 344px;
    height: 360px;
    padding: 70px 42px 0;
  }
}

.title {
  padding-top: var(--ds-space-3-x);
  font-size: var(--ds-font-size-m);
}

.body {
  padding-top: var(--ds-space-2-x);
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-m);
}
