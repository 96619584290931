.cookieBanner {
  padding: var(--ds-space-4-x) var(--ds-space-3-x);
  background: var(--ds-color-pureWhite);
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 30px 5px rgb(0 0 0 / 50%);
  z-index: 4;
  will-change: transform;

  @media (--xs) {
    padding: var(--ds-space-8-x) var(--ds-space-4-x);
  }
}

.cookieBannerInner {
  margin: 0 auto;
  max-width: 1024px;
  display: flex;
  flex-direction: column;

  @media (--s) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.cookieBannerContent {
  margin-bottom: var(--ds-space-4-x);

  @media (--s) {
    margin-bottom: 0;
    max-width: 630px;
  }
}

.cookieBannerTitle {
  margin-bottom: var(--ds-space-2-x);
}

.cookieBannerButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--s) {
    max-width: calc(310px + var(--ds-space-4-x));
    margin-left: var(--ds-space-4-x);
  }
}

.cookieBannerButtons button {
  margin-bottom: 0;
  white-space: nowrap;
}

.cookieBannerButtons button:first-of-type {
  margin-bottom: var(--ds-space-2-x);
}
