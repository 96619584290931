.container {
  --border-color: #e8e8e8;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0.25rem;
  border-top: 1px solid var(--border-color);

  .ctaWrapper:first-child {
    border-right: 1px solid var(--border-color);
  }
}

.ctaWrapper {
  --text-color: #656565;
  --hover-color: #5234b6;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  transition: color 0.1s ease-in-out;

  :hover {
    color: var(--hover-color);
  }

  .svg {
    fill: var(--text-color);
    margin-right: 0.5rem;

    :hover {
      color: var(--hover-color);
    }
  }
}

.ctaButton {
  --text-color: #656565;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--text-color);
}

.toggled {
  --hover-color: #5234b6;

  color: var(--hover-color);
}
