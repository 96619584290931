@value variables: '../../../../../variables.css';
@value layout-container-border-radius from variables;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--ds-color-pureWhite);
  padding: 0 var(--ds-space-3-x);

  @media (--m) {
    height: calc(418px + layout-container-border-radius);
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.content {
  max-width: 490px;
}

.title {
  line-height: 2.5rem;
  font-size: 2rem;

  @media (--m) {
    font-size: 2.625rem;
    line-height: 3.5rem;
  }
}

.body {
  line-height: 1.625rem;
  font-size: 1.125rem;

  @media (--m) {
    line-height: 2rem;
    font-size: 1.375rem;
  }
}

.imageWrapper {
  position: relative;
  width: 296px;
  height: 197px;
  margin-bottom: 1rem;

  @media (--m) {
    width: 393px;
    height: 262px;
    margin-bottom: 0;
  }
}

.divider {
  margin: 1rem 0;
  border-top: 1px var(--ds-color-gainsboro) solid;
}

.contactInformation {
  text-align: left;
  margin-bottom: 1rem;

  @media (--m) {
    margin-bottom: 0;
  }
}

.contactItem {
  display: flex;
  align-items: flex-start;

  @media screen and (--xs) {
    align-items: center;
  }
}

.contactItem p {
  line-height: 1.625rem;
  font-size: 1.125rem;
}

.contactItem:not(:last-child) {
  margin-bottom: 0.5rem;
}

.svg {
  margin-right: 1rem;
  min-height: 24px;
  min-width: 24px;
}
