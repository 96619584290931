.card {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
  background-color: var(--ds-color-pureWhite);
  border-radius: var(--ds-space-2-x);
  box-shadow: 0 2px 7px 0 rgb(0 0 0 / 10%);
  transition: box-shadow 200ms ease-in-out;
}

.hoverable {
  @media (hover) {
    &:hover {
      box-shadow: 0 var(--ds-space-2-x) var(--ds-space-4-x) 0 rgb(0 0 0 / 20%);
    }
  }
}
