/* stylelint-disable scale-unlimited/declaration-strict-value */
.container {
  padding: 2rem;
  border: 0.063rem solid #e1dafb;
  background-color: #f8f6ff;
  border-radius: 1rem;

  @media (--m) {
    width: 23.625rem;
    min-height: 22.875rem;
  }
}

.faqList {
  color: #311b77 !important;
  border-bottom: 1px solid #e1dafb;
  padding: 16px 0;
}

.faqList:nth-last-child(2) {
  border: none;
}
.faqList:nth-child(1) {
  padding-top: 0;
}
