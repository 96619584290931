.saveTag {
  padding: 6px var(--ds-space-1-x);
  margin-right: var(--ds-space-2-x);
  background-color: var(--ds-color-deepPurple);
  border-radius: 4px;
  color: var(--ds-color-iceBlue);
  font-size: var(--ds-font-size-xs);
  font-weight: var(--ds-font-weight-bold);
}

.text.text {
  max-width: 270px;
  color: var(--ds-color-mineShaft);
  font-size: 13px;
  line-height: 20px;

  a {
    color: var(--ds-color-toreaBay);
    font-weight: var(--ds-font-weight-semibold);
    text-decoration: underline;
  }

  @media (--s) {
    max-width: 100%;
    font-size: var(--ds-font-size-s);
  }
}
