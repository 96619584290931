/* This is needed due to defaults in frontend */
.sharedUiHeaderContainer li::before {
  content: none;
}

.sharedUiHeaderContainer {
  position: relative;
  z-index: 3;
}

.sharedUiHeaderTopLevel {
  display: flex;
  max-width: 1280px;
  min-height: 82px;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  line-height: 1.5rem;
}

.sharedUiSkipLink {
  position: absolute;
  top: 0;
  left: 0;
}

.sharedUiSkipLink:not(:focus, :active) {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.sharedUiSkipLink:focus {
  z-index: 10;
  overflow: auto;
  width: auto;
  height: auto;
  padding: 16px 24px;
  background-color: #fafafa;
}

.sharedUiHeaderContainer a:hover,
.sharedUiHeaderContainer a:focus {
  text-decoration: underline;
}
