/* stylelint-disable scale-unlimited/declaration-strict-value */
.navCard {
  position: relative;
  display: flex;
  width: 100%;
  height: 122px;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  border: 0.063rem solid #e1dafb;
  background-color: #f8f6ff;
  border-radius: 1rem;

  @media (--s) {
    height: 142px;
  }
}

.textContainer {
  padding-bottom: 16px;
}

.heading {
  width: 143px;
  padding-top: 16px;
  padding-bottom: 12px;
  color: #8f1dbf;
  font-size: 18px;
  font-weight: 700;
}

.link {
  display: flex;
  height: 26px;
  align-items: center;
  color: #1565c0;
  font-size: 16px;
  @media (--xxxs) {
    font-size: 18px;
  }
}

.icon {
  padding-left: 12px;
}

.imageWrapper {
  display: flex;
  width: 114px;

  @media (--xxxs) {
    width: 128px;
  }

  @media (--s) {
    width: 160px;
  }
}
