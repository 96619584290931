.container {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  box-shadow: rgba(168 168 168 / 30%) 0 2px 5px 1px;
  overflow: hidden;
  margin: 0.5rem;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 195px;
  padding: 1rem 1rem 0.5rem;
}

.title {
  --title-color: #2f2f2f;

  color: var(--title-color);
  font-size: 1.125rem;
  font-weight: 600;
}

.subtitle {
  --title-color: #2f2f2f;

  color: var(--title-color);
  font-size: 1rem;
  line-height: 1.55;
}

.badge {
  position: absolute;
  z-index: 1;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  translate: -0.5rem -1rem;
}

.reserved {
  background-color: var(--ds-color-gainsboro);
  color: var(--ds-color-mineShaft);
}

.discount {
  background-color: var(--ds-color-deepPurple);
  color: var(--ds-color-iceBlue);
}
