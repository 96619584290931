.container {
  @media (--m) {
    width: 100%;
    position: absolute;
  }
}

.inner {
  @media (--m) {
    margin-top: -50px;
    display: flex;
    justify-content: flex-end;
    padding-right: calc(16px + env(safe-area-inset-right));
    padding-left: calc(16px + env(safe-area-inset-left));
  }

  @media (--l) {
    margin-top: -70px;
  }
}

.link {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trustpilotStars {
  width: 115px;
  margin-right: 11px;
}

.trustpilotLogo {
  width: 72px;
}

.trustpilotScore {
  color: var(--ds-color-mineShaft);
  font-size: 17px;
  line-height: 26px;
  font-weight: 700;
  margin-right: 8px;
}
