.container {
  @media (--s) {
    max-width: 480px;
  }

  @media (--m) {
    max-width: 550px;
  }
}

.title {
  padding-bottom: var(--ds-space-2-x);
}

.subtitle {
  padding-bottom: var(--ds-space-8-x);
}
