.overlay {
  opacity: 0.6;
  width: 100vw;
  height: 100vh;
  background-color: var(--ds-color-pureBlack);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
}
