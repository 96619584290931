.video {
  width: 200px;
  height: 113px;
  padding-top: 0;
}

.video img {
  border-radius: 0;
}

.rtParagraph {
  margin-bottom: var(--ds-space-1-x);
}

.rtParagraph a {
  color: var(--ds-color-toreaBay);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
}

.rtParagraph a:visited {
  color: var(--ds-color-toreaBay);
}
