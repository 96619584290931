/* stylelint-disable scale-unlimited/declaration-strict-value */
.navItem {
  display: none;

  @media (--m) {
    display: inline-block;
  }
}

.navItemDropdown {
  display: none;

  @media (--m) {
    position: absolute;
    top: 0;
    display: block;
    width: auto;
    padding: 3rem;
    background: #fff;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 18px 22px rgb(0 0 0 / 25%);
    color: #311b77;
    gap: 7rem;
  }

  @media (--l) {
    gap: 12.188rem;
  }
}

.navDropdownWrapper {
  position: absolute;
  z-index: 4;
  right: 0;
  left: 0;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.navDropdownBG {
  position: absolute;
  z-index: 0;
  background-color: rgb(0 0 0 / 20%);
  inset: 0;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(10px);
  }
}

.carCareMenuContainer {
  display: flex;
  gap: 0.25rem;
}

.menuContainer {
  display: flex;
  gap: 5rem;
}
