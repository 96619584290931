@keyframes skeleton {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.base {
  display: table;

  &::before {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 0;
    width: 100%;
    height: 1em;
    background-color: rgb(0 0 0 / 0%);
    border-radius: var(--ds-space-half-x);
    content: '';
    pointer-events: none;
    transition: background-color 100ms linear;
  }
}

.active {
  position: relative;
  color: rgb(0 0 0 / 0%);

  &::before {
    animation: skeleton 1.2s linear infinite;
    background: var(--ds-color-gainsboro)
      linear-gradient(
        to right,
        var(--ds-color-gainsboro),
        var(--ds-color-wildSand)
      );
    background-size: 300% 300%;
  }

  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    text-decoration: none;
  }
}
