.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: var(--ds-space-2-x) var(--ds-space-2-x) var(--ds-space-8-x);

  @media (--m) {
    margin: var(--ds-space-2-x) var(--ds-space-2-x) var(--ds-space-12-x);
  }
}

.errorPageTitle {
  margin: var(--ds-space-2-x) 0;
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-xxl);
  font-weight: var(--ds-font-weight-semibold);
}

.errorPageSubTitle {
  margin-bottom: var(--ds-space-4-x);
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-l);
  font-weight: var(--ds-font-weight-semibold);
}

.errorPageImage {
  margin: 0 0 var(--ds-space-4-x) var(--ds-space-4-x);

  svg {
    display: flex;
    width: 300px;
    height: 274px;
    fill: currentcolor;
    stroke: none;
    vertical-align: middle;
  }
}

.actionButtons {
  display: flex;
}

.actionButtons a:last-child {
  margin-left: 16px;
}
